import React from 'react';
import PropTypes from 'prop-types';
// import theme from 'styles/theme';
// import { Main } from 'styles/privilege-plans';
// import { PrivilagesStyled } from '../../styles/privilages';

const BlackFridayDealsTable = ({ togglePurchaseModal }) => (
  <main
  // className="privilege-plans-styled-main"
  >
    {/* <div className="privilages-styled"> */}
    <section className="pricing chart">
      <div className="container">
        <div className="pricing-table">
          <div
            className="pricing-single bg-lightGrey"
            style={{
              // backgroundColor: theme.colors.lightGrey,
              zIndex: 1,
              width: '33.3%',
            }}>
            <h2 className="price-title">Pro</h2>
            <div className="price-amount" style={{ minHeight: '120px' }}>
              <h3>
                <span>$1000</span>
                $150
              </h3>
              <h4 className="center">3-Year Plan</h4>
            </div>
            <div className="price-features">
              <p>
                <span>1</span>
                &nbsp;member
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Members are our users, who&apos;ll own this plan & be able
                    to decide changes/sharing rights of the plan at anytime.
                  </span>
                </span>
              </p>
              <p>
                Unlimited Project & Pages
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Add your websites, web apps or graphics inside projects on
                    ruttl.
                  </span>
                </span>
              </p>
              <p>
                Unlimited Collaborators
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Collaborators can add comments and make edits.
                  </span>
                </span>
              </p>
              <p>
                Unlimited Guests
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Guests can only view and comment on the projects, without
                    the need of signing up or logging in.
                  </span>
                </span>
              </p>
              <p>
                All Integrations
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Integrate with your existing work tools to ease your
                    feedback & review process.
                  </span>
                </span>
              </p>
              <p>
                Web App Support
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Review logged-in screens and password-gated websites.
                  </span>
                </span>
              </p>
              <p>
                <span>500 GB</span>
                &nbsp; Workspace Storage&nbsp;
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Storage for your video comments, replaced assets, & attached
                    files in comments.
                  </span>
                </span>
              </p>
              <p>-</p>
              <p>-</p>
              <p>-</p>

              <p>
                <button
                  type="button"
                  className="price-button"
                  onClick={togglePurchaseModal(0)}>
                  Purchase Plan
                </button>
              </p>
            </div>
          </div>
          <div
            className="pricing-single pro-pricing"
            style={{
              width: '33.3%',
            }}>
            <h2 className="price-title">
              Team&nbsp;
              <span className="popular-badge">Most Popular</span>
            </h2>
            <div className="price-amount" style={{ minHeight: '120px' }}>
              <h3>
                <span>$2000</span>
                $300
              </h3>
              <h4 className="center">3-Year Plan</h4>
            </div>
            <div className="price-features">
              <p>
                <span>5</span>
                &nbsp;members
              </p>
              <p>
                <span>Unlimited</span>
                &nbsp;projects
              </p>

              <p>Unlimited collaborators</p>
              <p>Unlimited Guests</p>
              <p>All Integrations</p>
              <p>Web App Support</p>
              <p>
                <span>1 TB</span>
                &nbsp; Workspace Storage&nbsp;
              </p>
              <p>
                <span style={{ fontWeight: 600 }}>CNAME</span>
              </p>
              <p>
                <span style={{ fontWeight: 600 }}>Custom Branding</span>
              </p>
              <p>Dedicated Relationship Manager</p>
              {/* <p>
                <span style={{ fontWeight: 600 }}>Custom Branding</span>
              </p>
              <p>
                <span style={{ fontWeight: 600 }}>CNAME</span>
              </p> */}
              <p>
                <button
                  type="button"
                  className="price-button active-button"
                  onClick={togglePurchaseModal(1)}>
                  Purchase Plan
                </button>
              </p>
            </div>
          </div>
          <div
            className="pricing-single bg-lightGrey"
            style={{
              // backgroundColor: theme.colors.lightGrey,
              zIndex: 1,
              width: '33.3%',
            }}>
            <h2 className="price-title">Enterprise</h2>
            <div className="price-amount" style={{ minHeight: '120px' }}>
              <h3>
                <span>$4000</span>
                $600
              </h3>
              <h4 className="center">3-Year Plan</h4>
            </div>
            <div className="price-features">
              <p>
                <span>20</span>
                &nbsp;member
              </p>
              <p>
                <span>Unlimited</span>
                &nbsp;projects
              </p>
              <p>
                Unlimited collaborators
                {/* <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Collaborators can add comments and make edits.
                  </span>
                </span> */}
              </p>
              <p>
                Unlimited Guests
                {/* <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Guests can only view and comment on the projects, without
                    the need of signing up or logging in.
                  </span>
                </span> */}
              </p>
              <p>All Integrations</p>
              <p>
                Web App Support
                {/* <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Review logged-in screens and password-gated websites.
                  </span>
                </span> */}
              </p>
              <p>
                <span>Unlimited</span>
              </p>
              <p>
                <span style={{ fontWeight: 600 }}>CNAME</span>
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Access ruttl on your preferred custom domain.
                  </span>
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 600 }}>Custom Branding</span>
                <span className="pop-info">
                  <svg width="15px" viewBox="0 0 7.6 7.6">
                    <path
                      d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                      fill="#6552ff"
                    />
                  </svg>
                  <span className="info-text">
                    Customise ruttl as per your brand colours.
                  </span>
                </span>
              </p>
              <p>Dedicated Relationship Manager</p>
              <p>
                <button
                  type="button"
                  className="price-button"
                  onClick={togglePurchaseModal(2)}>
                  Purchase Plan
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* </div> */}
  </main>
);

BlackFridayDealsTable.propTypes = {
  togglePurchaseModal: PropTypes.func,
};

export default BlackFridayDealsTable;
